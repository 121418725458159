const dayjs = useDayjs()

export const useStats = async () =>{

    const store = useStatsStore()
    const errorStore = useErrorStore()

    // Function to check if a string is in 'DD/MM/YYYY' format
    const isDateFormat = (input) => {
        // Regex to match 'DD/MM/YYYY' format
        const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
        return dateRegex.test(input);
    }
    

    const getStats = async () => {
        try {
            const {data:stats,message} = await useAxiosGet('analytics/dashboard')
            store.setStats(stats)
            return stats
        } catch (error) {
            console.log("Dashboard Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getChart = async (from=null,to=null) => {
        try {
            let fromDate = from == null ? null : dayjs(from).format('MM/DD/YYYY')
            let toDate = to ==null ? null : dayjs(to).add(1, 'day').format('MM/DD/YYYY')
            const {data:chart,message} = await useAxiosGet(`analytics/activity-chart?from=${fromDate}&to=${toDate}`)
            console.log('chart_timeline',chart)
            let chart_labels = chart.map(c=>{
                console.log('inside chart',c.Date)
                let value
                if(!isNaN(c.Date)){
                    value = `week ${c.Date}`
                }
                else if( isDateFormat ){
                    value = dayjs(c.Date).format('MMM DD')
                }
                return value
            })
            let chart_duration = chart.map(c=>c.Duration)
            return {chart_labels:chart_labels,chart_duration:chart_duration}
        } catch (error) {
            console.log("Dashboard Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    return { getStats,getChart }
}